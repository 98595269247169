<template>
        <div class="outer-wrapper">
            <!--<img class='backImg' :src="imgUrl" alt='' />-->
            <div class='wrap'>
                <div class="med-wrapper">
                    <div class='title'>
                        <p class="title-right title-text">欢迎登录数据中心！</p>
                        <p class="title-left title-text" @click="toHome">返回主页</p>
                        <div class="arrow" @click="toHome"></div>
                    </div>
                    <div class="login-logo">
                    <img class='login-logo-img' :src="logoImgUrl" alt='' />
                    </div>
                    <div class='main'>
                        <div class='main-input'>
                            <el-input @focus="handleRewrite" class='m-i' placeholder="请输入手机号" v-model="values.account"
                                clearable><i slot="suffix" class=""></i></el-input>
                            <el-input type="password" @focus="handleRewrite" class='m-i harfleft' placeholder="请输入验证码" v-model="values.password"
                                clearable><i slot="suffix" class=""></i></el-input><Button type="info" class="harfright" :disabled="codeStat" @click="getAccessCode">{{codeText}}</Button>
                            <div class='error-box m-i' v-show="errorFlag.isCode"><span><i class=""></i></span><span
                                    class="error-text">{{errorFlag.text}}</span></div>
                            <el-button class='m-i m-bt' type="primary" plain @click="toLogin">登录</el-button>
                        </div>

                        <div class='footer'>
                            
                            <!-- <span class="f-text bottom-right">
                                <router-link :to="{name:'register'}">注册账户</router-link>
                            </span> -->
                        </div>
                    </div>
                </div>
            </div>
            <Spin fix v-show="loading">登录中...</Spin>
        </div>

</template>
<script>
    export default {
        name: "loginBox",
        components: {
        },
        data() {
            return {
                values: {
                    account: "",
                    password: "",
                },
                errorFlag: {
                    isCode: false,
                    text: ""
                },
                imgUrl: process.env.BASE_URL + "img/city_logo/login_bg.jpg",
                logoImgUrl: process.env.BASE_URL + "img/city_logo/login_logo.png",
                index_url:"/index",
                codeStat:false,
                codeText:'获取验证码',//3秒后可重新获取
                loading:false,
            }
        },
        props: {
        },
        created() {
        },
        mounted() {
        },
        methods: {
            //非空验证
            handleRewrite() {
                this.errorFlag.isCode = false;
            },
            checkVal() {
                if (this.values.account == "") {
                    this.errorFlag.isCode = true;
                    this.errorFlag.text = "手机号不能为空";
                } else if (this.values.password == "") {
                    this.errorFlag.isCode = true;
                    this.errorFlag.text = "验证码不能为空";
                }
            },
            toHome(){
                location.href = "/";
            },

            toLogin() {
                this.checkVal();
                this.loading = true;
                this.$axios.post(this.$url + '/login', {
                    val: this.values
                }).then(response => {
                    if (response.data === 'error') {
                        this.loading = false;
                        this.errorFlag.isCode = true;
                        this.errorFlag.text = "验证码错误或已失效！";
                    } else {
                        localStorage.setItem(
                        "current_user_token",
                        response.data.user_token
                        );
                        let resAuthData = {
                        barRights: response.data.barRights,
                        sfileRight: response.data.sfileRight,
                        expireTime: Date.now() + 1000 * 3600 * 4
                        };
                        localStorage.setItem("authData", JSON.stringify(resAuthData));
                        if(response.data.barRights.indexOf('risk_l')!==-1||response.data.barRights.indexOf('super_all')!==-1){
                            location.href = this.index_url;
                        }else{
                            this.$router.push({'name':response.data.barRights[1]});
                        }
                    }

                }).catch(error => {
                    this.loading = false;
                    this.errorFlag.isCode = true;
                    this.errorFlag.text = error;
                })
            },
            getAccessCode(){
                if(/^\d{11}$/.test(this.values.account)){
                let initialVal = 60;
                this.codeStat = true;
                this.codeText = initialVal + '秒后可重新获取';
                let tmpId = setInterval(()=>{
                    if(initialVal>1){
                        initialVal = initialVal - 1;
                        this.codeText = initialVal + '秒后可重新获取';
                    }else{
                        this.codeStat = false;
                        this.codeText = '获取验证码';
                        clearInterval(tmpId);
                    }
                },1000);
                this.$axios.post(this.$url + '/accessCode', {
                    val: this.values
                }).then(response => {
                    if (response.data.stat === 'ok') {
                        this.$Message.success('验证码发送成功，有效期30分钟！');
                    } else {
                        this.$Message.error(
                            {
                                content:response.data.info,
                                duration:15
                            }
                            );
                    }

                }).catch(error => {
                    this.$Message.error(error);
                })
                }else{
                    this.$Message.error("手机号为空或格式不符，请重新填写！");
                }

            }
        }
    }
</script>


<style scoped>
    .outer-wrapper{
        width:100%;
        background-image: url("../../assets/login_bg.jpg");   
        background-attachment:scroll;
        overflow: hidden;
        background-repeat: no-repeat;
    }
    .backImg {
        width: 100%;
        max-width: 100%;
    }

    .wrap {
        position: relative;
        width: 500px;
        background: #fff;
        border-radius: 5px;
        text-align: center;
        left:50%;
        margin:9% 0 15% 0;
        padding-bottom: 100px;
    }

    .main {
        margin:0 auto;
        width: 300px;
        text-align: center;
    }

    .title {
        height: 36px;
        background-color: #993333;
        position: relative;
    }

    .login-logo{
        padding: 50px 0;

    }

    .login-logo-img{
        width:200px;

    }

    .title-text {
        font-size: 12px;
        color:#ddd;
        line-height: 40px;
        position: absolute;
    }

    .title-right{
        right:24px;
    }

    .title-left{
        left:24px;
        cursor: pointer;
    }

    .m-i {
        margin-top: 20px;
    }

    .harfleft{
        width:60%;
    }
    .harfright{
        height:40px;
        width:40%;
        position: relative;
        bottom:2px;
        font-size: 10px;
    }

    .m-bt {
        width: 100%;
        background-color: #993333;
        color:#ddd;
        border:none;
    }

    .m-bt:hover{
        background-color: #993333;
        border:none;
        color:#fff;
    }

    .error-box {
        color: red;
        font-size: 12px;
        text-align: left;
        margin-left: 2px;
    }

    .footer {
        text-align: right;
        height: 40px;
        line-height: 50px;
    }

    .f-text {
        color: #999;
        font-size: 14px;
    }

    .img-wrapper {
        position: absolute;
        top: 0;
    }

    .loginImg {
        height: 360px;
        border-radius: 5px;
    }

    .arrow{
        position: absolute;
        cursor: pointer;
        left:10px;
        top:14px;
        width:10px;
        height: 10px;
        background: url('../../assets/more1.png');
        background-size: 100% 100%;
    }
    
</style>